<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.name"
                        name="name"
                        rules="required"
                        label="Company Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.owner.email"
                        name="owner.email"
                        rules="required|email"
                        label="Email"
                      />
                    </div>
                    <div class="col-md-6">

                    </div>
                    <div class="col-md-6">
                      <SelectInput
                        v-model="business.timezone"
                        :options="timezones"
                        label="Timezone"
                      />
                    </div>
                  </div>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="passwordForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(changePassword)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password"
                      name="password"
                      rules="required|confirmed:confirm_password"
                      label="Password"
                      type="password"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password_confirmation"
                      vid="confirm_password"
                      name="confirm_password"
                      rules="required"
                      label="Confirm Password"
                      type="password"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingPassword || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },
  },

  mounted() {
    this.getBusiness()
  },

  methods: {
    updateBusiness() {
      this.loadingUpdate = true

      this.$store
        .dispatch('business/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getBusiness() {
      this.loadingBusiness = true

      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          this.business = business
          this.loadingBusiness = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.businesses.index' })
          this.loadingBusiness = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
